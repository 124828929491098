<template>
  <div class="my">
      <div class="head">{{nickname}}的双十一清单</div>
      <div class="my-content">
          <div class="content" v-for="(item,index) in list" :key="index">
              <div class="con-name">{{item.name}}</div>
              <div class="price">￥{{item.price}}</div>
              <!-- <input
                    readonly="true"
                    id="copy1"
                    style="outline: none;border: 0px; color: rgba(0,0,0,0.0);position: absolute;left:-200px; background-color: transparent"
                    :value="item.password"
                />
              <div class="copybtn bg" id="copyBtn" @click="copybtn"></div> -->
              <div class="copybtn bg" v-clipboard:copy="item.password" v-clipboard:success="onCopy" v-clipboard:error="onError"></div>
          </div>
      </div>
      <div class="btn">
          <div class="returnbtn bg" @click="returnbtn"></div>
          <div class="sharebtn bg" @click="sharebtn"></div>
      </div>
      <!-- 分享弹框 -->
      <div class="share-modal" v-if="share"  @click="knowbtn">
          <div class="modal">
              <img src="../assets/share1.png" alt="">
              <!-- <div class="title">点击右上角分享给好友</div>
              <button class="knowbtn">知道了</button> -->
          </div>
      </div> 
      <!-- 复制口令成功弹框 -->
      <div class="copy-modal" v-if="copy">
          <div class="copy">
              <div class="copy-top">复制口令赶紧去tb抢货</div>
              <div class="copy-bottom" @click="copytrue">OK</div>
          </div>
      </div>
      <div class="img" v-if="imgdis">
          <!-- <img src="" alt=""> -->
      </div>
  </div>
</template>

<script>
// import axios from 'axios'
 import {fileitem} from '../api/index.js'
export default {
    data(){
        return{
            share:false,
            list:[],
            copy:false,
            nickname:"",
            id:"",
            imgdis:false
        }
    },
    methods:{
        onCopy (e) {
        console.log("成功了"+e)
        this.copy = true
        },
        // 复制失败时的回调函数
        onError (e) {
        console.log("失败了"+e)
        },
        copytrue(){
            this.copy = false
        },
        returnbtn(){
            // this.$router.push("/")
             this.$router.push({ path: "/", query: { info: "info" } });
        },
        copybtn(e){
            // console.log(a)
            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
                //区分iPhone设备
                window.getSelection().removeAllRanges(); //这段代码必须放在前面否则无效
                var Url2 = document.getElementById("copy1"); //要复制文字的节点
                var range = document.createRange();
                // 选中需要复制的节点
                range.selectNode(Url2);
                // 执行选中元素
                window.getSelection().addRange(range);
                // 执行 copy 操作
                var successful = document.execCommand("copy");
                console.log(successful)
                // 移除选中的元素
                window.getSelection().removeAllRanges();
                this.copy = true
            } else {
                var input = document.getElementById("copy1");
                input.value = e.target.dataset.clipboardText; // 修改文本框的内容
                input.select(); // 选中文本
                document.execCommand("copy"); // 执行浏览器复制命令
                this.copy = true
            }
        },
        sharebtn(){
            this.share = true
            // this.$router.push("/Share")
        },
        knowbtn(){
            this.share = false
        },
         tunesharing() {
             let fd = new FormData()
             fd.append('url', location.href.split("#")[0])
            this.$axios.post(
                "https://h5api.xiaoxxx.cn/h5/YJMY/getJsSDKConfig",
                fd
                )
                .then((res) => {
                    // console.log(res)
                if (res.status === 200) {
                    let JsSDKConfig = res.data;
                    let appId1 = JsSDKConfig.appId;
                    let timestamp1 = JsSDKConfig.timestamp;
                    let nonceStr1 = JsSDKConfig.nonceStr;
                    let signature1 = JsSDKConfig.signature;
                    window.wx.config({
                    beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: appId1, // 必填，公众号的唯一标识
                    timestamp: timestamp1, // 必填，生成签名的时间戳
                    nonceStr: nonceStr1, // 必填，生成签名的随机串
                    signature: signature1, // 必填，签名，见附录1
                    jsApiList: ["onMenuShareAppMessage",'updateTimelineShareData','onMenuShareTimeline'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    });
                    this.rwxeady("");
                }
                });
            },
        rwxeady() {
            // console.log(nn)
            window.wx.ready(() => {
                var shareurl = 'https://shoppinglist.xiaoxxx.com/#/Share?id='+ this.id
                // 转发
                window.wx.onMenuShareAppMessage({
                title: this.nickname+"的双十一清单，快来抄作业！！", // 分享标题
                desc: "谁在喊要抄作业？", // 分享描述
                // link:"https://youngster-program.xiaoxxx.com?",
                link: shareurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:"https://h5case2.xiaoxxx.cn/202011/img/fx.png", // 分享图标
                success: function () {
                    // console.log("33333")
                },
                });
                // 分享到朋友圈
                window.wx.updateTimelineShareData({ 
                    title: this.nickname+"的双十一清单，快来抄作业！！", // 分享标题
                    link: shareurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl:"https://h5case2.xiaoxxx.cn/202011/img/fx.png", // 分享图标
                    success: function () {
                    // 设置成功
                    // console.log("22222")
                    }
                })
                window.wx.onMenuShareTimeline({
                    title: this.nickname+"的双十一清单，快来抄作业！！", // 分享标题
                    link: shareurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: "https://h5case2.xiaoxxx.cn/202011/img/fx.png", // 分享图标
                    success: function () {
                    // 用户点击了分享后执行的回调函数
                    }
                })
            });
        },
        ddshare(){
            var that = this
            //钉钉分享
            if (dd.ios || dd.android) {
            window.$.get('https://m.xiaoxxx.com/dd/getConfig', {
                "url": window.location.href
            }, function (msg) {
                var content = msg.content || msg.data;
                // console.log(content)
                var agentId = content.agentId;
                var corpId = content.corpId;
                var timeStamp = content.timeStamp;
                var nonceStr = content.nonceStr;
                var signature = content.signature;
                dd.config({
                    agentId: agentId,
                    corpId: corpId,
                    timeStamp: timeStamp,
                    nonceStr: nonceStr,
                    signature: signature,
                    type: 0,
                    jsApiList: ['runtime.info', 'biz.contact.choose',
                        'device.notification.confirm', 'device.notification.alert',
                        'device.notification.prompt', 'biz.ding.post',
                        'biz.util.openLink', 'biz.util.share', 'biz.navigation.setRight'
                    ]
                });
                dd.ready(function () {
                    dd.biz.navigation.setRight({
                        show: true,
                        control: true,
                        text: '分享',
                        onSuccess: function (result) {
                            dd.biz.util.share({
                                "style": 0,
                                "url": 'https://shoppinglist.xiaoxxx.com/#/Share?id='+ that.id,
                                "title": that.nickname+"的双十一清单，快来抄作业！！",
                                "content": "谁在喊要抄作业？",
                                "image": "https://h5case2.xiaoxxx.cn/202011/img/fx.png"
                            });
                        },
                        onFail: function (err) { }
                    });
                });
            }, "jsonp");
            };

        }
    },
    created(){
        let obj = JSON.parse(window.localStorage.getItem('shoplist'))
        this.list = obj.data
        this.nickname = obj.nickname
        // console.log(obj)
        fileitem(obj).then((res)=>{
            this.id = res.data.data.id
            console.log(res)
            // console.log(this.nickname)
            // let obj1 = {
            //     id:res.data.data.id,
            //     nickname:this.nickname
            // }
            // console.log(obj1)
            this.tunesharing()
            this.rwxeady()
            this.ddshare()
        })
        // console.log(this.list)
        if(this.list.length>7){
            this.imgdis = true
            console.log("1111")
        }

    }
}
</script>

<style scoped>
.img{
    width: 1rem;
    height: 1rem;
    animation: slide-up-down 1.5s ease-in-out alternate-reverse infinite;
    background-image: url('../assets/bottom.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom:0;
}
@keyframes slide-up-down {
  from {
    transform: translateY(-0.2rem);
  }
  to {
    transform: translateY(0.2rem);
  }
}
.copy-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.copy-modal .copy{
    width: 70%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1.6rem;
    background-color: white;
    border-radius: 0.1rem;
    text-align: center;
}
.copy-modal .copy .copy-top{
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.2rem;
    color: black;
}
.copy-modal .copy .copy-bottom{
    width: 100%;
    height: 0.6rem;
    line-height: 0.6rem;
    color: green;
    font-size: 0.25rem;
    border-top: solid 0.01rem #eeeeee;
}
.share-modal{
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
}
.share-modal .modal{
    width: 5.5rem;
    /* height: 3rem; */
    /* background-color: white; */
    /* margin: 2rem auto; */
    position: absolute;
    right: 0;
    top: 0.15rem;
}
.share-modal .modal img{
    width: 5rem;
    height: 4.5rem;
}
.share-modal .modal .title{
    font-size: 0.5rem;
    width: 5rem;
    margin: 0 auto;
}
.share-modal .modal .knowbtn{
    background-color: orange;
    color: white;
    height: 0.5rem;
    border: none;
    border-radius: 0.1rem;
}
.my{
    padding: 0.73rem 0.26rem;
    box-sizing: border-box;
    position: relative;
}
.head{
    font-size: 0.6rem;
    /* font-weight: bold; */
    margin-bottom: 1rem;
    font-family: puhui;
  }
  .my-content{
    overflow-x: hidden;
    overflow-y: scroll;
      text-align: left;
    height: 7.5rem;
  }
  .content{
      display: flex;
      justify-content: space-between;
      font-size: 0.3rem;
      box-sizing: border-box;
      /* padding: 0 0.5rem 0 1rem; */
      margin-bottom: 0.5rem;
      align-items: center;
      text-align: left;
      font-weight: bold;
  }
  .content .con-name{
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style:puhui1;
      font-size: 0.44rem;
  }
  .content .price{
      text-align: left;
      margin: 0 ;
      width: 25%;
      font-style:heiti;
      font-size: 0.32rem;
  }
  .content .copybtn{
      /* background-color: orange; */
      /* border: none;
      border-radius: 0.1rem; */
      /* color: white; */
      height: 0.33rem;
      width: 1.33rem;
      background-image: url('../assets/copybtn.png');
  }
  .btn{
      position: fixed;
      bottom: 1.3rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
  }
  /* .btn button{
      background-color: orange;
      border: none;
      border-radius: 0.1rem;
      color: white;
      height: 0.7rem;
  } */
  .btn .returnbtn{
      margin-right: 0.5rem;
      background-image: url('../assets/mytbn.png');
      width: 3.18rem;
      height: 0.81rem;
  }
  .btn .sharebtn{
      background-image: url('../assets/share.png');
      width: 2.1rem;
      height: 0.81rem;
  }
</style>